'use client';

/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */

import { createContext, useContext, useEffect, useMemo, useState, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import QueryString from 'qs';
import { Loading } from '../loading';

import { encryptRequest, decryptResponse } from '@/app/utilities/helpers/security';
import { axiosInstance } from '@/app/utilities/helpers/axios';

export const AppSettingsContext = createContext({
  settings: {},
});

export function useAppSettingsContext() {
  return useContext(AppSettingsContext);
}

export function AppSettingsProvider({ children }) {
  const [settings, setSettings] = useState(false);

  const getSettings = useCallback(async () => {
    try {
      const body = {
        data: encryptRequest({}),
      };

      const response = await axiosInstance.post(
        'sOPyrVeIDGHte7uTQ0HDdcK3YtbMGCZg',
        QueryString.stringify(body)
      );

      const responseData = decryptResponse(response.data);

      if (responseData.success) {
        setSettings(responseData.settings);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('[get playlists error]', e);
    }
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const value = useMemo(
    () => ({
      settings,
    }),
    [settings]
  );

  return (
    <AnimatePresence>
      <AppSettingsContext.Provider value={value}>{children}</AppSettingsContext.Provider>
    </AnimatePresence>
  );
}
