/* eslint-disable @next/next/no-img-element */
import { Box, useTheme } from '@mui/material';
import { m } from 'framer-motion';

export const Loading = () => {
  const theme = useTheme();
  return (
    <m.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        sx={{
          inset: 0,
          position: 'fixed',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bgcolor: theme.palette.background.default,
          zIndex: 1200,
          flexDirection: 'column',
        }}
      >
        <img
          style={{ width: '250px' }}
          src="/assets/illustrations/logo-animation.gif"
          alt="logo-animation"
        />
      </Box>
    </m.div>
  );
};
