import(/* webpackMode: "eager" */ "/home/yukilogictitans/public_html/smartersMax/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/theme/typography.js\",\"import\":\"Public_Sans\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"],\"variable\":\"--font-public-sans\"}],\"variableName\":\"primaryFont\"}");
;
import(/* webpackMode: "eager" */ "/home/yukilogictitans/public_html/smartersMax/frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/theme/typography.js\",\"import\":\"Barlow\",\"arguments\":[{\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"subsets\":[\"latin\"],\"display\":\"swap\",\"fallback\":[\"Helvetica\",\"Arial\",\"sans-serif\"],\"variable\":\"--font-barlow\"}],\"variableName\":\"secondaryFont\"}");
;
import(/* webpackMode: "eager", webpackExports: ["MotionLazy"] */ "/home/yukilogictitans/public_html/smartersMax/frontend/src/app/components/animate/motion-lazy.js");
;
import(/* webpackMode: "eager", webpackExports: ["AppSettingsProvider"] */ "/home/yukilogictitans/public_html/smartersMax/frontend/src/app/components/contexts/app-settings-context.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/yukilogictitans/public_html/smartersMax/frontend/src/app/components/progress-bar/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/yukilogictitans/public_html/smartersMax/frontend/src/app/components/settings/drawer/settings-drawer.js");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/home/yukilogictitans/public_html/smartersMax/frontend/src/app/components/settings/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/yukilogictitans/public_html/smartersMax/frontend/src/app/components/snackbar/snackbar-provider.js");
;
import(/* webpackMode: "eager" */ "/home/yukilogictitans/public_html/smartersMax/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/yukilogictitans/public_html/smartersMax/frontend/src/app/theme/index.js");
